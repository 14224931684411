import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect } from "preact/hooks";
import { fingerprint } from "stores/fingerprint";

const FP = () => {
  useEffect(() => {
    const setFp = async () => {
      const start = Date.now();
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      fingerprint.set(visitorId);
    };

    setFp();
  }, []);

  return <></>;
};

export default FP;
